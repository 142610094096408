import './Capabilities.css';
import {VerticalTimeline, VerticalTimelineElement,} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import React from 'react';

function Capabilities() {

    const timeline = [
        {
          /* icon: workIcon, */
          /* 1969 */
          date: 1969,
          title: 'Birth of the Internet',
          subtitle: 'California',
          desc: '',
        },
        {
            /* icon: workIcon, */
            /* 1969 */
            date: 1971,
            title: 'Email',
            subtitle: '',
            desc: 'Ray Tomlinson created a program that gave users the ability to send messages between connected computers using the ARPANET system',
          },
          {
            /* icon: workIcon, */
            /* 1969 */
            date: 1971,
            title: 'Instant Messaging',
            subtitle: '',
            desc: 'Created as a chat function on a government computer network by American scientist Murray Turoff to help exchange information that would aid the government during emergencies',
          },
        {
          /* icon: workIcon, */
          date: 1982,
          title: 'eCommerce',
          subtitle: '',
          desc: 'Boston Computer Exchange launches as an online marketplace for people to buy and sell used computers',
        },
        {
            /* icon: workIcon, */
            date: 1982,
            title: 'Online Learning',
            subtitle: '',
            desc: 'The Western Behavior Sciences Institute uses computer conferencing to provide a distance education program for business executes',
          },
        {
            /* icon: workIcon, */
            date: 1985,
            title: 'Domain Names',
            subtitle: '',
            desc: 'The first domain name created was by nordu.net a Scandinavian research collaboration. The first domain name registered was also in 1985, by Symbolics Inc, a computer systems company in Cambridge, Mass.',
          },
          {
            /* icon: workIcon, */
            date: 1989,
            title: 'Affiliate Marketing',
            subtitle: '',
            desc: 'William J. Tobin set up the first affiliate marketing program every for his company PC Flowers & Gifts',
          },
        {
            /* icon: workIcon, */
            date: 1990,
            title: 'World Wide Web',
            subtitle: '',
            desc: 'Sir Tim Berners-Lee, a British computer scientist at CERN, created three fundamental technologies that remain the foundation of todays web: HyperText Markup Language (HTML), Uniform Resource Identifier (URI, also known as URL), Hypertext Transfer Protocol (HTTP)',
          },
          {
            /* icon: workIcon, */
            date: 1990,
            title: 'Search Engine',
            subtitle: '',
            desc: 'Early online journalists used an Internet search tool called Archie',
          },
          {
            /* icon: workIcon, */
            date: 1991,
            title: 'Website',
            subtitle: '',
            desc: 'The first website launched at the European Organization for Nuclear Research, CERN, by British computer scientist Tim Berners-Lee. It contained information about the World Wide Web Project and how to create web pages and learn about hypertext',
          },
        {
            /* icon: workIcon, */
            date: 1993,
            title: 'Mobile Apps',
            subtitle: '',
            desc: 'IBM created the first mobile app with SIMON, a touchscreen smartphone with ten built-in apps, including Email, Calendar, Address Book, Note Pad, and Calculator',
          },
          {
            /* icon: workIcon, */
            date: 1993,
            title: 'Online Streaming',
            subtitle: '',
            desc: 'The company Xerox PARC in California broadcasted a gig by a some of its colleagues apart of a band called Severe Tire Damage over a niche network called Mbone, which was the first instance of live streaming both audio and video',
          },
         
          {
            /* icon: workIcon, */
            date: 1994,
            title: 'Online Banking',
            subtitle: '',
            desc: 'First website for banking services launched by Standford Credit Union',
          },
          {
            /* icon: workIcon, */
            date: 1994,
            title: 'Blog',
            subtitle: '',
            desc: 'First blog was Links.net, created by then-student Justin Hall as a place to publish his writing',
          },
        {
            /* icon: workIcon, */
            date: 1996,
            title: 'Social Media',
            subtitle: '',
            desc: 'Six Degrees, founded by Andrew Weinreich, combined popular features such as profiles, friends lists and school affiliations in one service',
          },
          {
            /* icon: workIcon, */
            date: 1996,
            title: 'Online Navigation',
            subtitle: '',
            desc: 'MapQuest created the first online mapping and turn-by-turn directions service',
          },
          {
            /* icon: workIcon, */
            date: 2006,
            title: 'Cloud Hosting',
            subtitle: '',
            desc: 'Amazon Web Services (AWS) first popularized cloud computing as the first cloud provider with the Amazon Elastic Computing Cloud (EC2)',
          },
          {
            /* icon: workIcon, */
            date: 2009,
            title: 'Cryptocurrency',
            subtitle: '',
            desc: 'Bitcoin  was the first cryptocurrency created, launched by a computer programmer or group of programmers under the pseudonym Satoshi Nakamoto',
          },
          {
            /* icon: workIcon, */
            date: 2023,
            title: 'Present',
            subtitle: '',
            desc: '',
          },
  
        /*
        { icon: starIcon }, */
      ];


    return(
<>

<VerticalTimeline>
        {timeline.map((t, i) => {
          const contentStyle =
            i === 0
              ? { background: 'rgb(33, 150, 243)', color: '#fff' }
              : undefined;
          const arrowStyle =
            i === 0
              ? { borderRight: '7px solid  rgb(33, 150, 243)' }
              : undefined;
            var dateTime;
            if (t.date - 1969 == 0) {
                dateTime = "Year 0"
            }
            else {
                dateTime = (t.date - 1969) + " AI"
            }
          return (
            <VerticalTimelineElement
              key={i}
              className="vertical-timeline-element--work"
              contentStyle={contentStyle}
              contentArrowStyle={arrowStyle}
              date={dateTime}
             /* {...t.icon} */
            >
              {t.title ? (
                <React.Fragment>
                  <h3 className="vertical-timeline-element-title">{t.title}</h3>
                  {t.subtitle && (
                    <h4 className="vertical-timeline-element-subtitle">
                      {t.subtitle}
                    </h4>
                  )}
                  {t.desc && <p>{t.desc}</p>}
                </React.Fragment>
              ) : undefined}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
<span>Birth of Internet</span>
<span>Cloud database</span>
<span>Affiliate marketing</span>
<span></span>
<span>Present</span>
</>

    );
}

export default Capabilities;