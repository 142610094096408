import Blog from '../components/Blog.js';


const Home = () => {
    return(
        <>
       <Blog></Blog>
        </>
    )
}


export default Home;

