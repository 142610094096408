import './App.css';
import { Routes , Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import BizOnline from './pages/BizOnline';
import DriveLyft from './pages/DriveLyft';
import InControl from './pages/InControl';
import Investing from './pages/Investing';
import MapApps from './pages/MapApps';
import PodcastRise from './pages/PodcastRise';
import Logo from './components/Logo';
import Footer from './components/Footer';

import Test from './pages/Test';
import Lexcel from './pages/Lexcel';
import Tool from './pages/Tool';
import PageNotFound from './pages/PageNotFound';
import DevOps from './pages/DevOps';
import Gina from './pages/Gina';


function App() {
  return (
    <>
      <div className="App">
{/* <Logo/> */}
        <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/online" element={<BizOnline />} />
        <Route path="/devops" element={<DevOps />} />

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />


        <Route path="/gina" element={<Gina />} />

        {/* <Route path="/drive-lyft" element={<DriveLyft />} /> */}


        {/*
        <Route path="/control" element={<InControl />} />
        <Route path="/investing" element={<Investing />} />
        <Route path="/map-apps" element={<MapApps />} />
        <Route path="/podcast" element={<PodcastRise />} />

  */}
{/*
          <Route path="/tool" element={<Tool />} />
          <Route path="/home" element={<Home />} />

          <Route path="/test" element={<Test />} />
          <Route path="/1" element={<Lexcel />} />
*/}
        </Routes>

      {/* <Footer /> */}
       
    
      </div>
    </>
  );
}

export default App;
