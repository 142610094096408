import { Link } from 'react-router-dom';
import hunt from '../components/img/hunt.png';

const Gina = () => {
    return(
        <>
      <h1 style={{textAlign: 'center'}}>Job Hunt</h1>
      <br/>
       <div style={{ overflow: 'hidden' }}>
      <iframe
        title="Career"
        style={{ width: '100%', height: '100vh', border: '0',
        transform: 'scale(2.7)',
transformOrigin: '0 0',
       }}
        src="https://app.fabric.microsoft.com/view?r=eyJrIjoiMTI4ODA0NjMtMDcxMC00Yzc1LWIxOTUtZTExZDJiNDc1NTNmIiwidCI6ImFlODg2MzQ1LWFhZjUtNDhmYi04ZWJmLTAwZTYwNmM0NjA1OSIsImMiOjN9&pageName=ReportSectionb4b717b6cd1ce90ec09e"
        allowFullScreen={true}
      ></iframe>
    </div>
    <img src = {hunt} alt = "hunt" />





        </>
    )
}


export default Gina;