import { Link } from 'react-router-dom';


const PageNotFound = () => {
    return(
        <>
       <div className="padding">  

      <h5>That page does not exist.  </h5>

        <h5><Link to="/">Return to Home</Link></h5>

</div>
        </>
    )
}


export default PageNotFound;